
import VimeoVideo from './default-slice'
import YoutubeVideo from './youtube'
export default {
  name: 'VideoEmbed',
  props: {
    slice: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
  },
  computed: {
    component() {
      const mappings = {
        'default-slice': VimeoVideo,
        youtube: YoutubeVideo,
      }
      return mappings[this.variation]
    },
    variation() {
      return this.slice.variation
    },
  },
}
