
import get from 'lodash.get'
import themeMixin from '~/mixins/theme'

export default {
  mixins: [themeMixin],
  props: {
    slice: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
  },
  computed: {
    hasSuptitle() {
      return get(this.slice, 'primary.suptitle')
    },
    hasTitle() {
      return get(this.slice, 'primary.title[0].text')
    },
    hasDescription() {
      return get(this.slice, 'primary.description[0].text')
    },
    hasLink() {
      return get(this.slice, 'primary.buttonText')
    },
    buttonIcon() {
      return `arrow-right-${this.themeContrast}`
    },
  },
}
